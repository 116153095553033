
import { Vue, Options } from 'vue-class-component';
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import axios from 'axios';
import router from '../router';

@Options({
    components: {
        'user-outlined': UserOutlined,
        'lock-outlined': LockOutlined
    }
})
export default class Login extends Vue {
    isLoginError: boolean = false;
    username = '';
    password = '';
    loading = false;

    async handleSubmit() {
        if (this.username === null || this.username === undefined || this.username.length <= 0) {
            message.error('Please enter your email or phone number!');
            return;
        }

        if (this.password === null || this.password === undefined || this.password.length <= 0) {
            message.error('Please enter your password!');
            return;
        }

        this.loading = true;

        try {
            const res = await axios.post(`${process.env.VUE_APP_API_URL!}/login`, {
                username: this.username,
                password: this.password
            });
            const data = await res.data;
            if (data.error) {
                this.loading = false;
                message.error(data.error_message);
            } else {
                message.success('Successfully logged in!');
                window.localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!, data.api_token);
                router.push({
                    name: 'extension_table'
                });
            }
        } catch (error) {
            this.loading = false;
            message.error('Sorry, something went wrong. Please check your internet connection and try again later.');
            return;
        }
    }

    mounted() {
        const token = window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!);
        if (token !== null && token !== undefined && token.length > 0) {
            router.push({
                name: 'extension_table'
            });
        }
    }
}
